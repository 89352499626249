@import '~ks-react-design-system/Styles/typography';

.modalRoot :global(.modal__label.label) {
  @include kds-label-m;
}

.extraMoney {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: var(--kds-border-s) solid var(--kds-color-border-primary);
  padding-bottom: var(--kds-spacing-mplus);
  margin-bottom: var(--kds-spacing-mexc);
}

.extraMoneyRow {
  @include kds-label-m-strong;
  display: flex;
  justify-content: space-between;
  margin: var(--kds-spacing-s) 0;
}

.termsWrapper {
  margin-bottom: var(--kds-spacing-m);

  :global {
    .link.link--m {
      @include kds-paragraph-s;
    }

    .unordered-list--m .list-item+.list-item {
      margin-top: var(--kds-spacing-s);
    }

    .unordered-list {
      margin-bottom: var(--kds-spacing-mplus);
    }
  }
}

.termsTitle {
  @include kds-heading-2xs-strong;
  margin-bottom: var(--kds-spacing-m);
}


.terms {
  @include kds-paragraph-s;
  color: var(--kds-color-text-tertiary);
}

:global {
  .modal__label {
    @include kds-label-m;
    color: var(--kds-color-text-tertiary);
  }
}
