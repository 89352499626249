@import '~ks-react-design-system/Styles/typography';
@import 'src/styles/media';

.icon {
  margin-bottom: var(--kds-spacing-mplus);
}

.debt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include kds-heading-xs-strong;
  margin-bottom: var(--kds-spacing-m);

  @include mobile {
    @include kds-heading-2xs-strong;
    margin-bottom: var(--kds-spacing-s);
  }
}

.description {
  @include kds-paragraph-m;
  text-align: center;
  margin-bottom: var(--kds-spacing-m);

  @include mobile {
    margin-bottom: 0;
  }
}
