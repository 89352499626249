@import 'src/styles/media';

.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin-bottom: 72px;

  @include mobile {
    max-width: 100%;
    margin-bottom: var(--kds-spacing-l);
  }
}

.phone {
  width: 200px;
  min-height: var(--kds-size-4xs);
  margin-bottom: var(--kds-spacing-s);
}

.title {
  min-height: var(--kds-size-s);

  @include mobile {
    min-height: var(--kds-size-xs);
  }
}

.body {
  display: flex;
  gap: var(--kds-spacing-l);

  @include desktop {
    gap: var(--kds-spacing-mplus);
  }

  @include tablet {
    flex-direction: column;
  }
}

.topUp {
  min-height: 376px;

  @include tablet {
    min-height: 288px;

    &:not(:first-of-type) {
      display: none;
    }
  }
}
