@import 'ks-react-design-system/Styles/typography';
@import 'src/styles/media';

.title {
  @include kds-heading-xs-strong;
  margin-bottom: var(--kds-spacing-m);
}

.description {
  @include kds-paragraph-m;
  margin: var(--kds-spacing-m) 0;

  @include mobile {
    margin-bottom: var(--kds-spacing-s);
  }
}

.button {
  margin-top: var(--kds-spacing-m);

  @include mobile {
    margin-top: var(--kds-spacing-s);
  }

  @include tablet {
    width: 100%;
  }
}
