@import '~ks-react-design-system/Styles/fonts';
@import '~ks-react-design-system/Styles/typography';
@import 'media';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  @include font-settings;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

.modal__title {
  @include kds-heading-s-strong;

  @include mobile {
    @include kds-heading-2xs-strong;
  }
}
