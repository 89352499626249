@import 'variables';

@mixin mobile {
  @media only screen and (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: $desktop) {
    @content;
  }
}
