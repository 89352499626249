.list {
  &:global(.unordered-list) {
    margin-bottom: var(--kds-spacing-l);
    margin-left: var(--kds-spacing-splus);
  }

  :global {
    li.list-item + li.list-item {
      margin-top: 0;
    }
  }
}
