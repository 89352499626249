@import 'src/styles/media';

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--kds-spacing-s);
  text-align: center;
  margin-bottom: calc(var(--kds-spacing-xlplus) - var(--kds-spacing-m));

  @include mobile {
    margin-bottom: 0;
  }
}

.iconWrapper {
  margin-bottom: var(--kds-spacing-m);
}
