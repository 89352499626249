@import 'src/styles/media';
@import '~ks-react-design-system/Styles/typography';

.modalRoot :global(div.modal__content) {
  padding-top: var(--kds-spacing-xl);
}

.icon {
  margin-bottom: var(--kds-spacing-mplus);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title:global(.kds-typography) {
  margin-bottom: var(--kds-spacing-m);

  @include mobile {
    @include kds-heading-2xs-strong;
    margin-bottom: var(--kds-spacing-s);
  }
}

.text:global(.kds-typography) {
  margin-bottom: calc(var(--kds-spacing-xlplus) - var(--kds-spacing-m))
}
