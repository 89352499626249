@import 'src/styles/mixins';
@import '~ks-react-design-system/Styles/typography';

.wrapper {
  @include grid(12);
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.logo {
  margin: var(--kds-spacing-l) 0;
}

.footer {
  @include kds-paragraph-s;
  color: var(--kds-color-text-placeholder);
  margin: var(--kds-spacing-mplus) 0 var(--kds-spacing-m);
}
