@import 'src/styles/media';

.wrapper {
  display: flex;
  justify-content: flex-end;
}

.wrapperCenter {
  justify-content: center;
}

.button, .link {
  @include mobile {
    width: 100%;
  }
}
