@import 'src/styles/media';

.choiceGroup {
  margin-bottom: var(--kds-spacing-l);

  :global {
    .radio-group__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--kds-spacing-s);

      .radio {
        margin-left: 0 !important;

        @include mobile {
          min-width: unset;
        }
      }
    }

    .radio-group__hint_placeholder {
      display: none;
    }
  }
}

.label:global(.kds-typography) {
  margin-bottom: var(--kds-spacing-s);
}
