@import 'src/styles/mixins';

.wrapper {
  display: flex;
  gap: var(--kds-spacing-l);
  justify-content: space-between;
  color: var(--kds-color-text-primary);

  @include desktop {
    gap: var(--kds-spacing-mplus);
  }

  @include tablet {
    flex-direction: column;
  }
}

.item {
  flex: 1;
  padding: var(--kds-spacing-l);
  border: var(--kds-border-s) solid var(--kds-color-border-primary);
  border-radius: var(--kds-border-radius-m);

  @include tablet {
    padding: var(--kds-spacing-mplus);
  }
}
