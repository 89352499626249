@import '~ks-react-design-system/Styles/typography';
@import 'src/styles/media';

.wrapper {
  margin-bottom: var(--kds-spacing-l);

  @include desktop {
    margin-bottom: var(--kds-spacing-mplus);
  }
}

.tag {
  margin-bottom: var(--kds-spacing-s);
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--kds-spacing-m);

  @include mobile {
    gap: var(--kds-spacing-s);
  }
}

.title {
  @include kds-heading-xl-strong;
  color: var(--kds-color-text-title);

  @include desktop {
    @include kds-heading-l-strong;
  }

  @include tablet {
    @include kds-heading-s-strong;
  }
}

.description {
  max-width: 694px;
  @include kds-paragraph-m;

  @include desktop {
    max-width: 100%;
  }
}
