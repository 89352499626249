@import '~ks-react-design-system/Styles/typography';
@import 'src/styles/media';

$max-width: calc(var(--kds-size-6xl) * 4 - var(--kds-size-6xs));
$desktop-width: calc(#{$max-width} - var(--kds-size-xs));
$tablet-width: calc(#{$desktop-width} - var(--kds-size-5xs));
$mobile-width: calc(#{$max-width} - calc(var(--kds-size-5xl) * 2));

.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: calc(var(--kds-spacing-mexc) + var(--kds-spacing-2xl));
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;

  @include desktop {
    max-width: $desktop-width
  }

  @include tablet {
    max-width: $tablet-width;
  }

  @include mobile {
    max-width: $mobile-width;
  }
}

.title {
  @include kds-heading-m-strong;
  margin-bottom: var(--kds-spacing-mplus);

  @include mobile {
    @include kds-heading-2xs-strong;
    margin-bottom: var(--kds-spacing-m);
  }
}

.subtitle {
  @include kds-paragraph-m;
  margin-bottom: var(--kds-spacing-mplus);
}
