@import '~ks-react-design-system/Styles/atoms';
@import 'src/styles/media';
@import 'src/styles/variables';

@mixin spacings() {
  padding: var(--kds-spacing-l) 0;

  @include mobile {
    padding: var(--kds-spacing-mplus) 0;
  }
}

@mixin grid($columns) {
  width: calc(#{$columns} * var(--kds-size-xl) + ((#{$columns} - 1) * var(--kds-size-2xs)));
  margin: 0 auto;

  @include desktop {
    width: calc(#{$columns} * var(--kds-size-s) + ((#{$columns} - 1) * var(--kds-size-4xs)));
  }

  @include tablet {
    width: calc(100% - var(--kds-spacing-xl));
  }

  @include mobile {
    width: calc(100% - var(--kds-spacing-mexc) * 2);
  }
}


