@import '~ks-react-design-system/Styles/typography';

.wrapper {
  border-color: var(--kds-palette-grey-20);
  border-width: var(--kds-border-s);

  &BorderTop {
    border-top-style: solid;
    padding-top: var(--kds-spacing-mexc);
  }

  &BorderBottom {
    border-bottom-style: solid;
    padding-bottom: var(--kds-spacing-mplus);
    margin-bottom: var(--kds-spacing-mexc);
  }
}

.label {
  @include kds-label-m-strong;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--kds-spacing-mexc);

  &:last-child {
    margin-bottom: var(--kds-spacing-s);
  }
}
